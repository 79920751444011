import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import scrollArrow from '../img/Scroll_Arrow.svg';

const StyledIndexPage = styled.article`
	.masthead-section {
		background-color: ${(props) => props.theme.colors.primary};
		position: relative;
		min-height: 100vh;
		height: 100vh;
		overflow: hidden;

		> .container {
			height: 100%;

			> .columns {
				height: 100%;

				@media (max-width: 768px) {
					display: flex;
				}

				> .column {

					.buddha-logo {
						@media (max-width: 768px) {
							max-width: 70%;
						}
					}

					.scroll-arrow {
						position: relative;
						top: 10rem;

						img {
							position: relative;
							animation: bounce 3s infinite;
							animation-delay: 1s;
						}
					}
				}
			}
		}

		.mandala-bg {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			backface-visibility: hidden;

			img {
				width: 95%;

				@media (max-width: 768px) {
					transform: scale(1.5);
				}
			}
		}
	}
	/* end .masthead-section */

	.main-section {
		padding-top: 6rem;
		padding-bottom: 6rem;
		background-position: 25% center;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: ${props => props.theme.colors.quaternary};

		h2 {
			@media (max-width: 878px) {
				line-height: 1.1;
			}
		}

		@media (max-width: 768px) {
			background-position: right center;

			h2 {
				margin-bottom: 1rem;
			}

			.featured-image {
				margin-right: -2rem;
				margin-left: -2rem;
			}
		}

		@media (min-width: 769px) {
			.featured-image {
				display: none;
			}
		}
	}
	/* end .main-section  */

	.cta-section {
		background-color: ${(props) => props.theme.colors.tertiary};
		padding-top: 12rem;
		padding-bottom: 12rem;
		position: relative;
		overflow: hidden;

		> .container {
			> .columns {
				.column {
					background-color: ${(props) => props.theme.colors.secondary};
					padding-top: 3rem;
					padding-bottom: 5rem;

					img {
						width: 70px;
						margin-bottom: 2rem;
					}

					.cta-text {
						font-size: 1.8rem;
						font-weight: ${(props) => props.theme.fonts.weights.regular};
						max-width: 620px;
						margin: 0 auto 3rem;
					}

					.cta-link {
						font-size: 4.6rem;
						color: #ffffff;
						display: inline-block;
						border-bottom: 2px solid #ffffff;

						&:hover {
							color: ${(props) => props.theme.colors.tertiary};
							border-bottom-color: ${(props) => props.theme.colors.tertiary};
						}

						@media (max-width: 650px) {
							font-size: 5vw;
						}

						@media (min-width: 769px) and (max-width: 1279px) {
							font-size: 3vw;
						}
					}

					@media (min-width: 769px) {
						padding-left: 10rem;
						padding-right: 10rem;
					}
				}
			}
		}

		.mandala-bg {
			position: absolute;
			top: 0;
			bottom: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			height: 100%;
			width: 100%;

			&:first-child {
				left: 60%;

				@media (max-width: 500px) {
					left: 0;
					right: 0;
				}
			}

			&:nth-child(2) {
				right: 60%;

				@media (max-width: 500px) {
					display: none;
				}
			}
		}
	}
	/* end .cta-section */
`;

function useScroll() {
	const [scrollY, setScrollY] = useState(0);

	const listener = (e) => {
		setScrollY(window.pageYOffset);
	};

	useEffect(() => {
		window.addEventListener('scroll', listener);
		return () => {
			window.removeEventListener('scroll', listener);
		};
	});

	return {
		scrollY,
	};
}

export const IndexPageTemplate = ({ masthead, main, cta }) => {
	const { scrollY } = useScroll();

	return (
		<StyledIndexPage>
			<h1 className="visually-hidden">{masthead.heading}</h1>
			<section className="section masthead-section">
				<div
					className="mandala-bg"
					style={{
						transform: `rotate(${scrollY * 0.015625}deg)`,
					}}
				>
					<img src={masthead.background_image.publicURL} alt="" />
				</div>
				<div className="container">
					<div className="columns is-centered is-vcentered">
						<div className="column is-half has-text-centered">
							<img
								className="buddha-logo"
								src={masthead.logo.publicURL}
								alt={`Little Buddha Cocktail Co.`}
							/>
							<div className="scroll-arrow">
								<img src={scrollArrow} alt={`Scroll down for more content`} />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section
				className="section main-section"
				style={{
					backgroundImage: `url(${
						!!main.background_image.childImageSharp
							? main.background_image.childImageSharp.fluid.src
							: main.background_image
					})`,
				}}
			>
				<div className="container">
					<div className="columns">
						<div className="column is-6 is-offset-6 text-column">
							<h2 dangerouslySetInnerHTML={{ __html: main.heading }} />
							<div className="featured-image">
								<PreviewCompatibleImage imageInfo={main.featured_image} />
							</div>
							<div dangerouslySetInnerHTML={{ __html: main.text }} />
						</div>
					</div>
				</div>
			</section>
			<section className="section cta-section">
				<div
					className="mandala-bg"
					style={{ backgroundImage: `url(${cta.background_image.publicURL})` }}
				/>
				<div
					className="mandala-bg"
					style={{ backgroundImage: `url(${cta.background_image.publicURL})` }}
				/>
				<div className="container">
					<div className="columns is-centered is-vcentered">
						<div className="column is-8 has-text-centered">
							<img
								src={cta.icon.publicURL}
								alt={`Little Buddha Cocktail Co. Logo`}
							/>
							<p
								className="cta-text"
								dangerouslySetInnerHTML={{ __html: cta.text }}
							/>
							<a href="mailto:hello@littlebuddhacc.com" className="cta-link">
								hello@littlebuddhacc.com
							</a>
						</div>
					</div>
				</div>
			</section>
		</StyledIndexPage>
	);
};

IndexPageTemplate.propTypes = {
	title: PropTypes.string,
};

const IndexPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<SEO title={frontmatter.title} keywords={['premium', 'cocktails', 'mocktails', 'delicious', 'beverages', 'lifestyle', 'mindful', 'mindfulness', 'conscious', 'fresh', 'refreshing']} />
			<IndexPageTemplate
				masthead={frontmatter.masthead}
				main={frontmatter.main}
				cta={frontmatter.cta}
			/>
		</Layout>
	);
};

IndexPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default IndexPage;

export const indexPageQuery = graphql`
	query IndexPage($id: String) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				masthead {
					heading
					logo {
						publicURL
					}
					background_image {
						publicURL
					}
				}
				main {
					heading
					text
					featured_image {
						alt
						image {
							childImageSharp {
								fluid(maxWidth: 750, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					background_image {
						childImageSharp {
							fluid(maxWidth: 1920, quality: 100) {
								src
							}
						}
					}
				}
				cta {
					icon {
						publicURL
					}
					text
					background_image {
						publicURL
					}
				}
			}
		}
	}
`;
